import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  BullhornPage1,
  Home,
  HubspotForFinance,
  HubspotForLegal,
  HubspotForManufacturing,
  HubspotForRetail,
  Privacy,
  TermsConditions,
  HubspotAppInstructions
} from "./pages";

import { ThemeProvider } from "styled-components";
import theme from "./theme";
import qs from "qs";

import TagManager from "react-gtm-module";
import { gtmId } from "./utils/config";
import { AppContext } from "./context";

!!gtmId && TagManager.initialize({ gtmId });

const App = () => {
  const [resetToken, setResetToken] = useState(undefined);
  const [extensionId, setExtensionId] = useState(undefined);
  const [isQaEnv, setIsQaEnv] = useState(false);

  useEffect(() => {
    const urlParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (urlParams.linkClickId) {
      localStorage.setItem("linkClickId", urlParams.linkClickId);
    }
    if (urlParams.rt) {
      setResetToken(urlParams.rt);
    }
    if (urlParams.extensionId) {
      setExtensionId(urlParams.extensionId);
    }
    if (urlParams.env && urlParams.env === "qa") {
      setIsQaEnv(true);
    }
    window.omEvents = window.omEvents || [];
    const omEvents = window.omEvents;
  }, []);

  return (
    <AppContext.Provider value={{ resetToken, extensionId, isQaEnv }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bh1" element={<BullhornPage1 />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/hubspot-setup" element={<HubspotAppInstructions />} />
            <Route
              path="/hubspot-for-manufacturing"
              element={<HubspotForManufacturing />}
            />
            <Route path="/hubspot-for-retail" element={<HubspotForRetail />} />
            <Route
              path="/hubspot-for-finance"
              element={<HubspotForFinance />}
            />
            <Route path="/hubspot-for-legal" element={<HubspotForLegal />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
