import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  AddedToWaitListMessage,
  ErrorPopup,
  Footer,
  Header,
  JoinToWaitList,
  PrimaryButton,
  PrimaryInput,
} from "../components";
import Benefits from "../components/Benefits";
import Preview from "../components/Preview";
import { EMAIL_REGEX } from "../utils/constants";
import {
  FORCE_CORPORATE_EMAILS,
  addEmailToWaitList,
} from "../services/waitList";
import { validateResetToken } from "../services/resetPassword";
import { AppContext } from "../context";

function Landing({
  descriptionContent,
  benefitsList,
  postContent,
  isHomePage,
  titleImageSrc,
  videoThumbnailSrc,
  whiteListButtonText,
}) {
  const { resetToken, extensionId, isQaEnv } = useContext(AppContext);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isDescriptionVisible, setsDescriptionVisible] = useState(true);
  const [recordsCount, setRecordsCount] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const goTo = (id) => {
    if (!id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const validateEmail = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const joinToWaitList = async (value) => {
    if (value === "") {
      return;
    }

    let invalidEmailMessage;
    if (!validateEmail(value)) {
      invalidEmailMessage = "Invalid email address";
    } else if (
      (value.toString().toLowerCase().endsWith("gmail.com") ||
        value.toString().toLowerCase().endsWith("yahoo.com") ||
        value.toString().toLowerCase().endsWith("hotmail.com") ||
        value.toString().toLowerCase().endsWith("outlook.com") ||
        value.toString().toLowerCase().endsWith("mail.com") ||
        value.toString().toLowerCase().endsWith("icloud.com")) &&
      FORCE_CORPORATE_EMAILS
    ) {
      invalidEmailMessage = "Please use your company email address";
    }

    if (invalidEmailMessage) {
      setPopupMessage(invalidEmailMessage);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }

    const response = await addEmailToWaitList(value);

    if (!response.success) {
      setPopupMessage(response.message);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }
    setsDescriptionVisible(false);
    setRecordsCount(response.recordsCount);
    goTo();
  };

  const sendMessageToExtension = (message) => {
    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(extensionId, message);
    } catch (error) {
      console.log(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    if (resetToken && extensionId) {
      const validateToken = async () => {
        const response = await validateResetToken(resetToken, isQaEnv);
        if (!response.success) {
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message:
                "We’re sorry, but we’re unable to reset your password. Please try again",
            },
          });
          return;
        }
        sendMessageToExtension({
          message: "open-reset-iframe",
          data: {
            invited: response.invited,
            resetToken,
            windowType: "popup",
          },
        });

        const url = new URL(window.location.href);
        url.searchParams.delete("rt");
        window.history.replaceState({}, "", url);
      };

      validateToken();
    }
  }, [resetToken]);

  return (
    <PageContainer>
      <Header
        isMobile={isMobile}
        recordsCount={recordsCount}
        goToJoin={() => goTo("enter-email-to-join")}
      />
      <Preview
        isMobile={isMobile}
        isDescriptionVisible={isDescriptionVisible}
        videoThumbnailSrc={videoThumbnailSrc}
        isHomePage={isHomePage}
      >
        {recordsCount ? (
          <AddedToWaitListMessage recordsCount={recordsCount} color="white" />
        ) : isMobile ? (
          <PrimaryButton
            label={whiteListButtonText}
            isSmall={true}
            onClick={() => goTo("enter-email-to-join")}
          ></PrimaryButton>
        ) : (
          <PrimaryInput
            placeholder="Enter your email address"
            buttonText={whiteListButtonText}
            isBordered={false}
            isShadowed={false}
            onConfirm={joinToWaitList}
          />
        )}
      </Preview>
      <Benefits
        descriptionContent={descriptionContent}
        benefitsList={benefitsList}
        titleImageSrc={titleImageSrc}
        postContent={postContent}
      />
      <JoinToWaitList
        isMobile={isMobile}
        joinToWaitList={joinToWaitList}
        whiteListButtonText={whiteListButtonText}
      />
      <Footer />
      {isShowPopup && <ErrorPopup message={popupMessage}></ErrorPopup>}
    </PageContainer>
  );
}

export default Landing;

const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.milk};
`;
