import axios from "../api/axios";
import { qaApiUrl } from "../utils/config";

const fetchAddEmailToWaitList = async (email) => {
  try {
    const linkClickId = localStorage.getItem("linkClickId") || undefined;

    const response = await axios.post(
      "/wait-list",
      JSON.stringify({ email, linkClickId })
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    localStorage.removeItem("linkClickId");

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data?.message || error.message,
    };
  }
};

const fetchValidateResetToken = async (token, isQaEnv) => {
  const config = {};
  if (isQaEnv) {
    config.baseURL = qaApiUrl;
  }
  try {
    const response = await axios.post(
      "/recover/validate",
      { rt: token },
      config
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response.data?.message || error.message,
    };
  }
};

export { fetchAddEmailToWaitList, fetchValidateResetToken };
